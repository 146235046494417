'use client';

import Centered from "#/app/_components/Centered";
import { GlobalErrorMessage } from "#/app/_components/GlobalErrorMessage";

export default function RootError({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  return (
    <GlobalErrorMessage reset={reset} />
  );
}
